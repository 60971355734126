<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-subheader>Name</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field label="Name" :value="userData.name"></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        <v-subheader>E-Mail</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field label="E-Mail" :value="userData.email"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-subheader>{{ $t('profileChangePassword') }}</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field
          :hint="$t('hintPasswordCurrent')"
          label="Current password"
          color="primary"
          v-model="currentPassword"
          name="currentPassword"
          type="password"
          @keypress.enter="show"
          required
        >
        </v-text-field>
        <v-text-field
          :hint="$t('hintPassword')"
          label="New password"
          color="primary"
          v-model="password"
          name="password"
          type="password"
          :rules="[rules.min, rules.required]"
          counter
          @keypress.enter="show"
          required
        >
        </v-text-field>
        <v-text-field
          :label="$t('hintPasswordNewConfirm')"
          color="primary"
          v-model="confirmedPassword"
          :rules="[passwordConfirmationRule]"
          name="confirmedPassword"
          type="password"
          counter
          @keypress.enter="show"
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn class="mt-2" color="red" outlined @click="deleteAccount()"> Delete </v-btn>
      </v-col>
      <v-col><v-btn class="mt-2 float-right" color="primary" @click="updateProfile()"> Update </v-btn> </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ProfileInfo',
  props: {},
  data: () => ({
    name: '',
    email: '',
    password: '',
    confirmedPassword: '',
    currentPassword: '',
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters',
    },
  }),
  computed: {
    userData() {
      const userData = this.$store.getters['user/userData'];
      return userData;
    },
    passwordConfirmationRule() {
      return () => this.password === this.confirmedPassword || 'Password must match';
    },
  },
};
</script>
