<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-subheader>Name</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field label="Name" :value="userData.name"></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        <v-subheader>E-Mail</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field label="E-Mail" :value="userData.email"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-subheader>{{ $t('password') }}</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field :label="$t('password')"></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ProfileAdresses',
  props: {},
  data: () => ({}),
  computed: {
    userData() {
      const userData = this.$store.getters['user/userData'];
      return userData;
    },
  },
};
</script>
