<template>
  <v-container fluid>
    <v-data-iterator :items="licenseData" hide-default-footer>
      <template v-slot:default="props">
        <v-row class="justify-center">
          <v-col v-for="item in props.items" :key="item.createdDate" cols="12" sm="12" md="12" lg="8">
            <v-card flat>
              <v-card-title class="font-weight-bold">Your Credits</v-card-title>
              <v-card-subtitle class="subheading"
                >If you've recently purchased credits, please sign out and sign back in to refresh your balance and view your
                updated credits.</v-card-subtitle
              >
              <v-divider></v-divider>

              <v-list dense>
                <v-list-item>
                  <v-list-item-content>{{ $t('Credits for') }}</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.ownerName }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>Credits left</v-list-item-content>
                  <v-list-item-content class="align-end">
                    <v-row no-gutters>
                      <v-col>
                        {{ item.credits_crawl }} <a @click="showPricingTable()">{{ $t('get more') }}</a></v-col
                      >
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>{{ $t('accountAllowedCrawlsPerRequest') }}</v-list-item-content>
                  <v-list-item-content class="align-end">
                    <v-row no-gutters>
                      <v-col>
                        {{ item.allowed_crawls_per_request }} <a @click="showPricingTable()">{{ $t('here too?') }}</a></v-col
                      >
                    </v-row>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>{{ $t('accountAllowedCrawlsPer24h') }} </v-list-item-content>
                  <v-list-item-content class="align-end">
                    <v-row no-gutters>
                      <v-col>
                        {{ item.allowed_crawls_per_domain_last_24h }}
                        <a @click="showPricingTable()">{{ $t('more?') }}</a></v-col
                      >
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content
                    >Your file to enable many pages per domain. Please create an empty file with that name in the root of the
                    website.</v-list-item-content
                  >
                  <v-list-item-content class="align-end">
                    {{ item.allowFile }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    To view your payment history and download invoices, please visit the<a
                      :href="`${portalLink}`"
                      target="_blank"
                    >
                      Stripe Customer Portal</a
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <!-- <v-banner color="secondary" elevation="5" outlined rounded>
      You want to crawl more pages? Or you want to get notified about changes on your page?
      <template v-slot:actions>
        <v-btn color="primary" text href="mailto:support@cookie-scanner.com" target="_blank">
          contact cookie scanner
        </v-btn></template
      ></v-banner
    > -->
  </v-container>
</template>

<script>
import { getFormattedDate } from '../../utils/date';

export default {
  name: 'LicenseInfo',
  props: {},
  data: () => ({}),
  computed: {
    licenseData() {
      const userData = this.$store.getters['user/userData'];
      return userData.licenses;
    },
    portalLink() {
      return process.env.VUE_APP_STRIPE_PORTAL;
    },
  },
  methods: {
    showPricingTable() {
      this.$store.commit('dialog/setName', {
        name: 'DialogPricingTable',
        data: {},
      });
    },
    formatDate(date) {
      return getFormattedDate(date);
    },
  },
};
</script>
