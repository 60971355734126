<template>
  <v-container>
    <v-card>
      <v-card-title class="text-center justify-center py-6">
        <h1 class="font-weight-bold display-3"></h1>
      </v-card-title>
      <v-tabs v-model="tab" centered icons-and-text>
        <v-tab href="#tab-1">
          {{ $t('accountYourPlan') }}
          <v-icon>{{ mdiSpeedometer }}</v-icon>
        </v-tab>

        <v-tab href="#tab-2">
          {{ $t('accountAccount') }}
          <v-icon>{{ mdiAccountOutline }}</v-icon>
        </v-tab>
        <!-- <v-tab href="#tab-3">
          Billing Address
          <v-icon>mdi-heart</v-icon>
        </v-tab> -->
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <LicenseInfo />
        </v-tab-item>
        <v-tab-item value="tab-2">
          <ProfileInfo />
        </v-tab-item>
        <v-tab-item value="tab-3">
          <ProfileAdresses />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<style>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
</style>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiSpeedometer, mdiAccountOutline } from '@mdi/js';

import LicenseInfo from '../components/account/LicenseInfo.vue';
import ProfileInfo from '../components/account/ProfileInfo.vue';
import ProfileAdresses from '../components/account/ProfileAdresses.vue';

export default {
  name: 'AccountOverview',
  components: {
    LicenseInfo,
    ProfileInfo,
    ProfileAdresses,
  },
  data: () => ({
    tab: null,
    mdiSpeedometer,
    mdiAccountOutline,
  }),
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
  },
  methods: {},
  metaInfo: {
    title: 'Your account - cookie-scanner.com',
    decription: 'Jo',
  },
};
</script>
